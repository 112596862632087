var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"center","padding-top":"10px"}},[_c('div',{style:(`width: ${_vm.entity.styleW}cm;`),attrs:{"id":"printDiv"}},[_c('div',{style:(`position: relative;width: ${_vm.entity.styleW}cm;height: ${
        _vm.entity.styleH *
        (Number(_vm.entity.printPages) > 1 ? Number(_vm.entity.printPages) : 1)
      }cm;page-break-after:always`)},_vm._l((_vm.entity.printlist),function(item,index){return _c('vue-drag-resize',{key:item.Id,attrs:{"isActive":false,"preventActiveBehavior":false,"isDraggable":false,"isResizable":false,"aspectRatio":false,"parentLimitation":"","parentW":_vm.getpx(_vm.entity.styleW, 'w', 'px'),"parentH":_vm.getpx(
            _vm.entity.styleH *
              (Number(_vm.entity.printPages) > 1 ? Number(_vm.entity.printPages) : 1),
            'h',
            'px'
          ),"w":item.w,"h":item.h,"x":item.x,"y":item.y,"minh":28,"minw":80}},[(item.type === '1')?_c('div',{style:(`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`)},[(item.Keys !== 'Time')?_c('div',[_vm._v(_vm._s(item.value))]):_c('div',[_vm._v(_vm._s(_vm.CreateTime))])]):_vm._e(),(item.type == '12')?_c('div',{staticClass:"duanl",style:(`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`)},[_c('div',{domProps:{"innerHTML":_vm._s(item.value)}})]):_vm._e(),(item.type == '3' && item.Keys == 'cusSeal' && _vm.cusSeal)?_c('div',{staticClass:"imgBox",style:(`border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`)},[_c('img',{attrs:{"src":_vm.cusSeal,"alt":""}})]):_vm._e(),(item.type == '3' && item.Keys == 'comSeal' && _vm.comSeal)?_c('div',{staticClass:"imgBox",style:(`border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`)},[_c('img',{attrs:{"src":_vm.comSeal,"alt":""}})]):_vm._e()])}),1)]),_c('div',{staticClass:"printBox"},[_c('div',{staticClass:"printSon",style:({
        minWidth: _vm.entity.styleW + 'cm',
      })},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getPdf()}}},[_vm._v("下载")]),_c('el-button',{directives:[{name:"print",rawName:"v-print",value:('printDiv'),expression:"'printDiv'"}],attrs:{"type":"primary"}},[_vm._v("打印")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.State == 1),expression:"State == 1"}],attrs:{"type":"primary","disabled":""}},[_vm._v("已完成 对符")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.State == 2),expression:"State == 2"}],attrs:{"type":"danger","disabled":""}},[_vm._v("已完成 不符")]),(_vm.State == 0)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submit(1)}}},[_vm._v("对符")]):_vm._e(),(_vm.State == 0)?_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.submit(2)}}},[_vm._v("不符")]):_vm._e()],1)]),_c('el-dialog',{attrs:{"close-on-click-modal":false,"close-on-press-escape":false,"show-close":false,"title":"对账函密码","visible":_vm.dialogVisible,"width":"300px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-input',{attrs:{"placeholder":"请输入对账函密码"},model:{value:(_vm.LetterCode),callback:function ($$v) {_vm.LetterCode=$$v},expression:"LetterCode"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.handleClose}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }