<template>
  <div style="display: flex; justify-content: center; padding-top: 10px">
    <div id="printDiv" :style="`width: ${entity.styleW}cm;`">
      <div
        :style="`position: relative;width: ${entity.styleW}cm;height: ${
          entity.styleH *
          (Number(entity.printPages) > 1 ? Number(entity.printPages) : 1)
        }cm;page-break-after:always`"
      >
        <vue-drag-resize
          v-for="(item, index) in entity.printlist"
          :key="item.Id"
          :isActive="false"
          :preventActiveBehavior="false"
          :isDraggable="false"
          :isResizable="false"
          :aspectRatio="false"
          parentLimitation
          :parentW="getpx(entity.styleW, 'w', 'px')"
          :parentH="
            getpx(
              entity.styleH *
                (Number(entity.printPages) > 1 ? Number(entity.printPages) : 1),
              'h',
              'px'
            )
          "
          :w="item.w"
          :h="item.h"
          :x="item.x"
          :y="item.y"
          :minh="28"
          :minw="80"
        >
          <div
            v-if="item.type === '1'"
            :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
          >
            <div v-if="item.Keys !== 'Time'">{{ item.value }}</div>
            <div v-else>{{ CreateTime }}</div>
          </div>
          <!-- 段落 -->
          <div
            v-if="item.type == '12'"
            class="duanl"
            :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
          >
            <div v-html="item.value"></div>
          </div>
          <!-- 签章 -->
          <div
            class="imgBox"
            :style="`border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
            v-if="item.type == '3' && item.Keys == 'cusSeal' && cusSeal"
          >
            <img :src="cusSeal" alt="" />
          </div>
          <div
            :style="`border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
            class="imgBox"
            v-if="item.type == '3' && item.Keys == 'comSeal' && comSeal"
          >
            <img :src="comSeal" alt="" />
          </div>
        </vue-drag-resize>
      </div>
    </div>
    <div class="printBox">
      <div
        class="printSon"
        :style="{
          minWidth: entity.styleW + 'cm',
        }"
      >
        <el-button type="primary" @click="getPdf()">下载</el-button>
        <el-button type="primary" v-print="'printDiv'">打印</el-button>
        <el-button v-show="State == 1" type="primary" disabled>已完成 对符</el-button>
        <el-button v-show="State == 2" type="danger" disabled>已完成 不符</el-button>
        <el-button v-if="State == 0" type="primary" @click="submit(1)"
          >对符</el-button
        >
        <el-button v-if="State == 0" type="danger" @click="submit(2)"
          >不符</el-button
        >
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      title="对账函密码"
      :visible.sync="dialogVisible"
      width="300px"
    >
      <el-input placeholder="请输入对账函密码" v-model="LetterCode"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="handleClose"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
export default {
  data() {
    return {
      htmlTitle: '对账函',
      Id: "",
      entity: {},
      comSeal: "",
      cusSeal: "",
      CreateTime: "",
      LetterCode: "",
      dialogVisible: false,
      loading: false,
      State: 0,
    };
  },
  async mounted() {
    console.log(this.$route.query);
    await this.getFormData();
  },
  methods: {
    // 对符不符
    submit(bl) {
      this.$http
        .post(
          "/Base/Base_Reconciliation/SubmitLetter?Id=" +
            this.Id +
            "&State=" +
            bl
        )
        .then((res) => {
          if (res.Success) {
            this.$message.success("操作成功");
            this.getFormData();
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 验证授权码
    handleClose() {
      this.loading = true;
      this.$http
        .post(
          "/Base/Base_Reconciliation/IntendedCode?Id=" +
            this.Id +
            "&LetterCode=" +
            this.LetterCode
        )
        .then((res) => {
          this.loading = false;
          if (res.Success && res.Data) {
            this.dialogVisible = false;
          } else {
            this.$message.error("授权码错误");
          }
        });
    },
    // 是否需要授权码
    async getPassStatus() {
      await this.$http
        .post("/Base/Base_Reconciliation/IsEnter?Id=" + this.Id)
        .then((res) => {
          if (res.Success) {
            this.dialogVisible = !res.Data;
          } else this.dialogVisible = true;
        });
    },
    // 获取表单数据
    async getFormData() {
      await this.$http
        .post(
          "/Base/Base_Reconciliation/GetRecDetail?Id=" + this.$route.query.prop
        )
        .then((res) => {
          console.log(res);
          if (res.Success) {
            this.Id = res.Data.Id;
            this.State = res.Data.State;
            this.CreateTime = res.Data.CreateTime.substring(0, 10);
            this.comSeal = res.Data.CompanySeal;
            this.cusSeal = res.Data.CustomerSeal;
            this.entity = JSON.parse(res.Data.SpanContent);
            if (this.State == 0) this.getPassStatus();
          }
        });
    },
    // 下载
    handleDownload() {
      this.$http.post("/Base/Base_Reconciliation/GetRecPDF?id=" + this.Id).then((res) => {
        if (res.Success) {
          downloadFile(res.Data, this.Id);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
      // px转cm：cm = pxValue/dpi*2.54
      //           px = cm/2.54*dpi
    },
  },
};
</script>

<style>
#printDiv {
  /* box-shadow: 0px 0px 5px #888; */
}
#printDiv .a {
  padding: 0 20px;
  border-bottom: 1px solid #000;
}
#printDiv .duanl {
  text-indent: 2em;
}
.imgBox img {
  width: 100%;
  height: 100%;
}
.printBox {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.printBox .printSon {
  padding: 10px 0;
  text-align: right;
  border-top: 1px solid #ccc;
}
</style>